//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-219:_5080,_1748,_8600,_3000,_3796,_9232,_6028,_3296;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-219')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-219', "_5080,_1748,_8600,_3000,_3796,_9232,_6028,_3296");
        }
      }catch (ex) {
        console.error(ex);
      }